import React from "react"
import styled from "@emotion/styled"
import { rhythm } from "../utils/typography"
import TagButton from "./TagButton"

const TAGS = [
  // { name: "dev", emoji: "👨‍💻" },
  { name: "agile", emoji: "🔄" },
  { name: "culture", emoji: "🎵" },
  // { name: "games", emoji: "🎮" },
  { name: "misc", emoji: "💭" },
]

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${rhythm(1)};
`

const ButtonWrapper = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;
`

const TagButtons = ({ currentTag, setCurrentTag }) => {
  return (
    <Wrapper>
      {TAGS.map(tag => {
        return (
          <ButtonWrapper key={tag.name}>
            <TagButton
              active={currentTag === tag.name}
              name={tag.name}
              emoji={tag.emoji}
              setCurrentTag={setCurrentTag}
            />
          </ButtonWrapper>
        )
      })}
    </Wrapper>
  )
}

export default TagButtons
