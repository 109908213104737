import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

const StyledTagButton = styled.button`
  font-size: 100%;

  display: inline-block;
  border: none;
  border-radius: 8px;
  padding: 0.25rem 1rem;
  margin: 0;
  text-decoration: none;
  background: var(--primary);
  color: var(--background);
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, color 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:hover {
    background: var(--primaryDarker);
    color: var(--textOnPrimaryDark);
  }

  &:focus {
    outline-offset: -4px;
    outline: 2px solid var(--primaryDarker);
  }

  &:active {
    transform: scale(0.95);
  }
`

const activeStyle = css`
    background: var(--buttonAlt);
    &:hover {
      background: var(--buttonAltDarker);
      color: var(--textOnButtonAltDark);
    }
`

const TagButton = ({active, emoji, name, setCurrentTag}) => {
  const icon = emoji ? <span aria-hidden> {emoji}</span> : null
  const handleClick = () => {
    if (active) {
      setCurrentTag(null)
    } else {
      setCurrentTag(name)
    }
  }
  return <StyledTagButton css={active ? activeStyle : null} onClick={handleClick}>#{name}{icon}</StyledTagButton>
}

export default TagButton
