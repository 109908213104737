import React, { useState } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { rhythm } from "../utils/typography"
import TagButtons from "../components/TagButtons"
import styled from "@emotion/styled"

const TagList = styled.small`
  margin-left: 8px;
  color: var(--textAlt);
`

const BlogIndex = ({ data, location }) => {
  const posts = data.allMarkdownRemark.edges
  const [currentTag, setCurrentTag] = useState(null)

  const postsList = posts
    .filter(
      ({ node }) =>
        !(currentTag && !node.frontmatter?.tags?.includes(currentTag))
    )
    .map(({ node }) => {
      const title = node.frontmatter.title || node.fields.slug
      const tags = node.frontmatter.tags
      return (
        <article key={node.fields.slug}>
          <header>
            <h3
              style={{
                marginBottom: rhythm(1 / 4),
              }}
            >
              <Link to={node.fields.slug}>{title}</Link>
            </h3>
            <small>{node.frontmatter.date}</small>
            {tags.length > 0 ? (
              <TagList>{tags.map(tag => `#${tag}`).join(", ")}</TagList>
            ) : null}
          </header>
          <section>
            <p
              dangerouslySetInnerHTML={{
                __html: node.frontmatter.description || node.excerpt,
              }}
            />
          </section>
        </article>
      )
    })

  const noPostsMessage = (
    <p>Hmm, looks like there aren't any posts in this category…</p>
  )

  return (
    <Layout location={location}>
      <SEO title="All posts" />
      <h1>Andy's Blog</h1>
      <p>
        Look, it's my blog! Barely merits its own page really, does it. Anyway,
        I'll occasionally post about dev stuff, tech, music, and so on. You're
        smart, you know what a blog is.
      </p>
      <TagButtons currentTag={currentTag} setCurrentTag={setCurrentTag} />
      {postsList.length > 0 ? postsList : noPostsMessage}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`
